import { useState, useEffect } from 'react';
import { Configuration, OpenAIApi } from 'openai';

const API_KEY = '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';
let config = new Configuration({ apiKey: API_KEY });
delete config.baseOptions.headers['User-Agent'];
const openAi = new OpenAIApi(config);

function App() {
  const [textInput, setTextInput] = useState('');
  const [desc, setDesc] = useState('');
  const [descSet, setDescSet] = useState(false);

  const [history, setHistory] = useState([]);

  const [buttonEnabled, setButtonEnabled] = useState(true);

  const onButton = async () => {
    // console.log(textInput);
    setHistory((oldHistory) => [...oldHistory, { role: 'user', content: textInput }]);
    // console.log(history);
    setTextInput('');

    // setHistory((oldHistory) => [
    //   ...oldHistory,
    //   { role: 'assistant', content: response.data.choices[0].message.content },
    // ]);
    // console.log(response.data.choices[0].message.content);
    // console.log(history);
  };

  const onButtonDesc = () => {
    setHistory([
      {
        role: 'system',
        content: desc,
      },
    ]);
    setDescSet(true);
  };

  useEffect(() => {
    console.log(history);
    if (history[history.length - 1]?.role === 'user') {
      setButtonEnabled(false);
      openAi
        .createChatCompletion({
          model: 'gpt-4',
          // model: 'gpt-3.5-turbo',
          messages: history,
        })
        .then((response) => {
          console.log(response.data.choices[0].message.content);
          setButtonEnabled(true);
          setHistory((oldHistory) => [
            ...oldHistory,
            { role: 'assistant', content: response.data.choices[0].message.content },
          ]);
        });
    }
  }, [history]);

  return (
    <div className='App'>
      {!descSet && (
        <textarea style={{ width: 800, height: 500 }} value={desc} onChange={(e) => setDesc(e.target.value)} />
      )}
      {!descSet && <button onClick={onButtonDesc}>Set</button>}
      {descSet && (
        <input style={{ width: 500 }} type='text' value={textInput} onChange={(e) => setTextInput(e.target.value)} />
      )}
      {buttonEnabled && descSet && <button onClick={onButton}>Send</button>}
      <div>
        {history.map((el, index) => {
          return (
            <div key={index}>
              {el.role === 'user' && 'Tu: ' + el.content}
              {el.role === 'assistant' && 'Roberta: ' + el.content}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default App;
